import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const FigureWrapper = styled.figure`
  position: relative;
  aspect-ratio: 4 / 3;
  max-width: 900px;
  width: 100%;
`;

export const MotionImage = styled(motion.img)`
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.brandGold};
  text-align: center;
  text-transform: uppercase;
`;

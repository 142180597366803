import React, { useEffect, useState } from 'react';
import { useDifferentLightsImage } from 'hooks/useDifferentLightsImage';
import LightsChange from 'components/LightsChange';
import { Container, FigureWrapper, MotionImage, Title } from './styled';
import { AnimatePresence } from 'framer-motion';
import { tryDifferentLightsId } from 'config';

export default function AnimatedImage({ cccid }: { cccid: string | undefined }) {
  const { imageSrc: coloredImage, status } = useDifferentLightsImage(Number(cccid));

  const [previousImage, setPreviousImage] = useState<string>(coloredImage || '');
  const [currentImage, setCurrentImage] = useState<string>(coloredImage || '');

  useEffect(() => {
    if (coloredImage) {
      setPreviousImage(currentImage);
      setCurrentImage(coloredImage);
    }
  }, [coloredImage, currentImage]);

  if (!cccid || !coloredImage || status === 'error') {
    return null;
  }

  return (
    <Container id={tryDifferentLightsId}>
      <Title>Try different light sources</Title>
      <FigureWrapper>
        <LightsChange />
        <AnimatePresence>
          <MotionImage
            key={`from-img-${previousImage}`}
            src={previousImage}
            alt={'previous image'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          />
          <MotionImage
            key={`to-img-${previousImage}`}
            src={coloredImage}
            alt={'colored image'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          />
        </AnimatePresence>
      </FigureWrapper>
    </Container>
  );
}

import React, { createContext, useState, useCallback, useContext } from 'react';

export type Time = 'day' | 'night';
export type LightType = 'Sunrise' | 'Sunset' | 'Daytime' | 'Hanging Lights' | 'Spot Lights';
export type LightSubType = 'East' | 'North' | 'West' | 'South' | 'Warm' | 'Cool';

export type LightsState = {
  time: Time;
  lightType: LightType;
  lightSubType: LightSubType;
};

export interface LightsContext extends LightsState {
  setLightsTime: (time: Time) => void;
  setLightType: (lightType: LightType) => void;
  setLightSubType: (lightSubType: LightSubType) => void;
}

const initialState: LightsState = {
  time: 'day',
  lightType: 'Sunrise',
  lightSubType: 'East',
};

const getDefaultsForTime = (time: Time): Pick<LightsState, 'lightType' | 'lightSubType'> => ({
  lightType: time === 'day' ? 'Sunrise' : 'Hanging Lights',
  lightSubType: time === 'day' ? 'East' : 'Warm',
});

const LightsContext = createContext<LightsContext | undefined>(undefined);

interface ProviderProps {
  children: React.ReactNode;
}

export function LightsProvider({ children }: ProviderProps) {
  const [state, setState] = useState<LightsState>(initialState);

  const stateUpdater = useCallback((updates: Partial<LightsState>) => {
    setState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  }, []);

  const contextValue: LightsContext = {
    ...state,
    setLightsTime: useCallback(
      (time: Time) => {
        stateUpdater({
          time,
          ...getDefaultsForTime(time),
        });
      },
      [stateUpdater]
    ),
    setLightType: useCallback(
      (lightType: LightType) => {
        stateUpdater({ lightType });
      },
      [stateUpdater]
    ),
    setLightSubType: useCallback(
      (lightSubType: LightSubType) => {
        stateUpdater({ lightSubType });
      },
      [stateUpdater]
    ),
  };

  return <LightsContext.Provider value={contextValue}>{children}</LightsContext.Provider>;
}

export function useLightsContext() {
  const context = useContext(LightsContext);

  if (context === undefined) {
    throw new Error('useLightsContext must be used within a LightsProvider');
  }

  return context;
}

import { darken } from 'util/helpers';
import styled, { css } from 'styled-components';

const primaryItemStyle = css<{ isActive?: boolean }>`
  background-color: ${({ theme }) => theme.colors.brandGold};
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  min-width: 6.875rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all ease 0.4s;
  border-radius: 0.25rem;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  box-shadow: 0px 65px 18px 0px rgba(0, 0, 0, 0), 0px 41px 17px 0px rgba(0, 0, 0, 0.01),
    0px 23px 14px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.09),
    0px 3px 6px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${({ theme, isActive }) => !isActive && darken(theme.colors.brandGold, 10)};
    color: ${({ theme, isActive }) => !isActive && theme.colors.brandGray050};
    box-shadow: 0px 65px 18px 0px rgba(0, 0, 0, 0);
  }

  @media (max-width: 600px) {
    font-size: 0.875rem;
    padding: 0.5rem;
    width: 100%;
  }
`;

export const MenuContainer = styled.section`
  transition: all ease 0.4s;
  transition-delay: 0.4s;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  @media (max-width: 600px) {
    flex-direction: row;
    bottom: 0.5rem;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
    gap: 0.5rem;
  }
`;

export const Menu = styled.div`
  position: relative;
  display: inline-block;
  font-family: 'Source Sans Pro', sans-serif;
  width: max-content;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    &:nth-child(2) {
      min-width: 150px;
    }
  }
`;

export const MenuItem = styled.div`
  ${primaryItemStyle}
  & svg {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const SubMenu = styled.div`
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 0.5rem;
  width: 0;
  height: 100%;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 0.5rem;

  ${Menu}:hover & {
    width: max-content;
    opacity: 1;
    transition: all 0.3s ease;
  }

  & svg {
    stroke: ${({ theme }) => theme.colors.brandGold};
  }

  @media (max-width: 600px) {
    flex-direction: column;
    height: 0;
    width: 100%;
    padding-left: 0;
    padding-bottom: 0.5rem;
    top: auto;
    left: 0;
    bottom: 100%;
    transform: translateY(0);

    ${Menu}:hover & {
      height: auto;
      width: 100%;
      opacity: 1;
      transition: all 0.3s ease;
    }
  }
`;

export const SubMenuItem = styled(MenuItem)<{ isActive?: boolean }>`
  justify-items: center;
  box-shadow: none;
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.brandGray400 : theme.colors.white};
  color: ${({ theme }) => theme.colors.brandGold};

  &:first-of-type {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: ${({ theme, isActive }) => !isActive && theme.colors.brandGray050};
    color: ${({ theme, isActive }) => !isActive && theme.colors.brandGold};
  }
`;

export const SwitchLightContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.125rem;
  max-height: 100%;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 65px 18px 0px rgba(0, 0, 0, 0), 0px 41px 17px 0px rgba(0, 0, 0, 0.01),
    0px 23px 14px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.09),
    0px 3px 6px 0px rgba(0, 0, 0, 0.1);
`;

export const SwitchLightItem = styled(MenuItem)<{ active?: boolean }>`
  ${primaryItemStyle}
  box-shadow: none;
  min-width: auto;
  padding: 0.375rem 0.5rem;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.brandGold : theme.colors.white};
  color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.brandGold)};

  & svg {
    stroke: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.brandGold)};
  }

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.brandGold : theme.colors.brandGray050};
  }

  @media (max-width: 600px) {
    padding: 0.375rem 0.5rem;
  }
`;

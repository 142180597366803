import React from 'react';
import { useLightsContext } from 'contexts/lightContext';
import { lightTimes } from './elements';
import { SwitchLightContainer, SwitchLightItem } from './styled';

export default function LightTimeSwitch() {
  const { time, setLightsTime } = useLightsContext();

  return (
    <SwitchLightContainer>
      {lightTimes.map(({ name, icon }) => (
        <SwitchLightItem key={name} onClick={() => setLightsTime(name)} active={time === name}>
          {icon}
        </SwitchLightItem>
      ))}
    </SwitchLightContainer>
  );
}

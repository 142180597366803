import React, { FC, useEffect, useRef, useState } from 'react';
import { MenuContainer, Menu, MenuItem, SubMenu, SubMenuItem } from './styled';
import LightTimeSwitch from './LightTimeSwitch';
import { LightType, LightSubType, useLightsContext } from 'contexts/lightContext';
import { dayTimes, directions, lights, lightTypes } from './elements';
import { CustomIcon } from '@thisisdevelopment/heritage-core-library';

function hideSubMenu(subMenuRef: React.RefObject<HTMLDivElement>) {
  subMenuRef.current && (subMenuRef.current.style.display = 'none');
  setTimeout(() => {
    subMenuRef.current && (subMenuRef.current.style.display = '');
  }, 300); // 300ms is long enough for most browsers to not re-initiate the hover
}

const LightsChange: FC = () => {
  const { time, setLightType, setLightSubType } = useLightsContext();
  const [selectedSecondColumn, setSelectedSecondColumn] = useState<{
    name: LightType;
    icon: JSX.Element;
  }>({
    name: 'Sunrise',
    icon: <CustomIcon name="Sunrise" />,
  });
  const [selectedThirdColumn, setSelectedThirdColumn] = useState<{
    name: LightSubType;
    icon: JSX.Element;
  }>({
    name: 'East',
    icon: <CustomIcon name="Direction" />,
  });

  const secondColumn = time === 'day' ? dayTimes : lights;
  const thirdColumn = time === 'day' ? directions : lightTypes;

  useEffect(() => {
    setSelectedSecondColumn(secondColumn[0]);
    setSelectedThirdColumn(thirdColumn[0]);
  }, [time, secondColumn, thirdColumn]);

  const subMenu1Ref = useRef<HTMLDivElement>(null);
  const subMenu2Ref = useRef<HTMLDivElement>(null);

  return (
    <MenuContainer>
      <Menu>
        <LightTimeSwitch />
      </Menu>
      {/* Time or Light Type Menu */}
      <Menu>
        <MenuItem>
          {selectedSecondColumn.icon}
          {selectedSecondColumn.name}
        </MenuItem>
        <SubMenu ref={subMenu1Ref}>
          {secondColumn.map(({ name, icon }) => (
            <SubMenuItem
              key={name}
              isActive={selectedSecondColumn.name === name}
              onClick={() => {
                setSelectedSecondColumn({ name, icon });
                setLightType(name);
                hideSubMenu(subMenu1Ref);
              }}
            >
              {icon}
              {name}
            </SubMenuItem>
          ))}
        </SubMenu>
      </Menu>

      {/* Direction or Warm/Cool Menu */}
      <Menu>
        <MenuItem>
          {selectedThirdColumn.icon}
          {selectedThirdColumn.name}
        </MenuItem>
        <SubMenu ref={subMenu2Ref}>
          {thirdColumn.map(({ name, icon }) => (
            <SubMenuItem
              key={name}
              isActive={selectedThirdColumn.name === name}
              onClick={() => {
                setSelectedThirdColumn({ name, icon });
                setLightSubType(name);
                hideSubMenu(subMenu2Ref);
              }}
            >
              {icon}
              {name}
            </SubMenuItem>
          ))}
        </SubMenu>
      </Menu>
    </MenuContainer>
  );
};

export default LightsChange;

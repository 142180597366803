import React from 'react';
import { CustomIcon } from '@thisisdevelopment/heritage-core-library';
import { LightType, Time, LightSubType } from 'contexts/lightContext';

export const lightTimes: { name: Time; icon: JSX.Element }[] = [
  { name: 'day', icon: <CustomIcon name="Sun" /> },
  { name: 'night', icon: <CustomIcon name="Moon" /> },
];

export const dayTimes: { name: Partial<LightType>; icon: JSX.Element }[] = [
  { name: 'Sunrise', icon: <CustomIcon name="Sunrise" /> },
  { name: 'Daytime', icon: <CustomIcon name="Sun" /> },
  { name: 'Sunset', icon: <CustomIcon name="Sunset" /> },
];

export const directions: { name: Partial<LightSubType>; icon: JSX.Element }[] = [
  { name: 'North', icon: <CustomIcon name="Direction" /> },
  { name: 'East', icon: <CustomIcon name="Direction" /> },
  { name: 'South', icon: <CustomIcon name="Direction" /> },
  { name: 'West', icon: <CustomIcon name="Direction" /> },
];

export const lights: { name: Partial<LightType>; icon: JSX.Element }[] = [
  { name: 'Hanging Lights', icon: <CustomIcon name="LightBulb" /> },
  { name: 'Spot Lights', icon: <CustomIcon name="LightBulb" /> },
];

export const lightTypes: { name: Partial<LightSubType>; icon: JSX.Element }[] = [
  { name: 'Warm', icon: <CustomIcon name="Fire" /> },
  { name: 'Cool', icon: <CustomIcon name="Group" /> },
];
